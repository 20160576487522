<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import axios from "axios";
import { ref, onMounted, computed } from "vue";
import {useStore} from "vuex";
import swal from "sweetalert2";
import * as XLSX from "xlsx";

const store = useStore();
const showModal = ref(false);
const paymentData = ref({
  amount: '',
  payment_reference_number: '',
  transactionID: '',
  ConversationID: '',
  OriginatorConversationID: '',
  ResponseCode: '',
  ResponseDescription: '',
});

const openPaymentModal = (item) => {
  paymentData.value.amount = item.amount.toString();
  paymentData.value.prn = item.payment_reference_number;
  paymentData.value.transactionID = item.id;
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};
//pay tax method
const submitPayment = async () => {
  const user = await store.state.user;

  try {
    const baseurl = process.env.VUE_APP_API_TAX_URL;
    let url = `${baseurl}/payment`;
    const response = await axios.post(url, paymentData.value, {
      headers: {
        "api-key": user.auth
      }
    });

    if (response.status === 200) {
      const updatedTransaction = transactions.value.find(t => t.id === paymentData.value.transactionID);
      if (updatedTransaction) {
        updatedTransaction.status = 2;
      }
      closeModal();
      swal.fire('Sent successfully!', '', 'success');
    } else {
      swal.fire('Error', 'An error occurred', 'error');
    }
  } catch (error) {
    console.log(error);
    swal.fire('Error', 'An error occurred', 'error');
  }
};
//pagination
const transactions = ref([]);
const currentPage = ref(1);
const perPage = ref(20);
const totalTransactions = ref(0);
const taxTypeFilter = ref("");

//function
const getTransactions = async () => {
  const user = store.state.user;

  if (!user) {
    console.log('User not authenticated');
    return;
  }
  //console.log('Auth Token:', user.auth);

  const baseurl = process.env.VUE_APP_API_TAX_URL;
  const url = `${baseurl}/remittance?page=${currentPage.value}&per_page=${perPage.value}&tax_type=${taxTypeFilter.value}&sort=tax_remittance.id%7Cdesc`;

  try {
    const response = await axios.get(url, {
      headers: {
        "api-key": user.auth,
      },
    });

    transactions.value = response.data.data;
    totalTransactions.value = response.data.total;
  } catch (error) {
    console.error('Error fetching transactions:', error);
  }
};

// Download CSV
const downloadCSV = () => {
  const visibleTransactions = transactions.value.map(item => ({
    ID: item.id,
    TaxType: item.tax_type,
    Amount: item.amount,
    StartDate: formatDate(item.start_date),
    EndDate: formatDate(item.end_date),
    PaymentRefNo: item.payment_reference_number,
    Status: item.status_description,
    MpesaRef: item.mpesa_reference,
    TransactionDate: formatDate(item.transaction_date),
  }));

  const ws = XLSX.utils.json_to_sheet(visibleTransactions);
  const csv = XLSX.utils.sheet_to_csv(ws);

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", "Tax_Remittance_Report.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// tax type filter and fetch transactions
const setTaxType = (type) => {
  taxTypeFilter.value = type;
  filterTransactions();
};

// Filter transactions on tax type
const filterTransactions = async () => {
  currentPage.value = 1;
  await getTransactions();
};

const totalPages = computed(() => {
  return Math.ceil(totalTransactions.value / perPage.value);
});

const prevPage = async () => {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
    await getTransactions();
  }
};

const nextPage = async () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1;
    await getTransactions();
  }
};
//timeformat
const formatDate = (datetime) => {
  if (!datetime) return '';
  return datetime.split('T')[0].split(' ')[0];
};

onMounted(() => {
  store.dispatch('checkUser');
  getTransactions();
});

</script>

<template>
  <div class="py-0 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-5">
                <h6 class="mb-0">Tax Remittance Report</h6>
              </div>
              <div class="col-7 d-flex justify-content-end align-items-center">
                <div class="dropdown me-3">
                  <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style="padding-top: 8px; padding-bottom: 6px; margin-top: 2px;"
                  >
                    <i class="fas fa-filter custom-icon-margin"></i>
                    <span class="text">Filter By Tax Type</span>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li @click="setTaxType('')">
                      <a class="dropdown-item" href="#">
                        <span class="badge bg-secondary me-2"></span> All
                      </a>
                    </li>
                    <li @click="setTaxType('IT_WHT_0222')">
                      <a class="dropdown-item" href="#">
                        <span class="badge bg-primary me-2"></span> IT_WHT_0222
                      </a>
                    </li>
                    <li @click="setTaxType('EXCISE')">
                      <a class="dropdown-item" href="#">
                        <span class="badge bg-success me-2"></span> EXCISE
                      </a>
                    </li>
                  </ul>
                </div>

                <argon-button color="dark" variant="gradient" class="mx-2" @click="downloadCSV">
                  <i class="fas fa-download me-2"></i>
                  CSV
                </argon-button>

              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive p-0">
                  <table class="table align-items-center mb-0">
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>Tax Type</th>
                      <th>Amount</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Payment Ref No</th>
                      <th>Status</th>
                      <th>Mpesa Ref</th>
                      <th>Transaction Date</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in transactions" :key="item.id" style="flex-direction: column-reverse">
                      <td>{{ item.id }}</td>
                      <td>{{ item.tax_type }}</td>
                      <td>{{ item.amount }}</td>
                      <td>{{ formatDate(item.start_date) }}</td>
                      <td>{{ formatDate(item.end_date) }}</td>
                      <td>{{ item.payment_reference_number }}</td>
                      <td>{{ item.status_description }}</td>
                      <td>{{ item.mpesa_reference }}</td>
                      <td>{{ formatDate(item.transaction_date) }}</td>
                      <td>
                        <argon-button v-if="item.status !== 2" color="secondary" variant="gradient" @click="openPaymentModal(item)">
                          Pay Tax
                        </argon-button>
                      </td>

                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Pagination buttons -->
            <div class="row mt-3">
              <div class="col-12 text-center">
                <argon-button @click="prevPage" :disabled="currentPage === 1">
                  Previous
                </argon-button>
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
                <argon-button @click="nextPage" :disabled="currentPage === totalPages">
                  Next
                </argon-button>
              </div>
            </div>

          </div>
          <!--modal-->
          <div v-if="showModal" class="modal fade show" tabindex="-1" style="display: block;">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Send PRN</h5>
                  <button type="button" class="btn-close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                  <form @submit.prevent="submitPayment">
                    <div class="mb-3">
                      <label for="amount" class="form-label">Amount</label>
                      <input type="text" id="amount" v-model="paymentData.amount" class="form-control" >

                    </div>
                    <div class="mb-3">
                      <label for="prn" class="form-label">PRN</label>
                      <input type="text" id="prn" v-model="paymentData.prn" class="form-control">
                    </div>
                    <div class="mb-3">
                      <label for="transactionID" class="form-label">Transaction ID</label>
                      <input type="text" id="transactionID" v-model="paymentData.transactionID" class="form-control">
                    </div>
                    <argon-button color="secondary" variant="outline" type="button" @click="closeModal" class="me-2">Cancel</argon-button>
                    <argon-button color="success" variant="gradient" type="submit">Submit</argon-button>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style>
.custom-icon-margin {
  margin-right: 8px;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

</style>

